body {
  font-family: 'Muli', sans-serif;
  color: rgba(0,0,0,1);
  overscroll-behavior: none;
  margin-top: 56px;
  font-size: 0.9rem;
  background-color: #212121;  /* fallback for old browsers */
  bbackground: -webkit-linear-gradient(to top, #A6FFCB, #12D8FA, #1FA2FF);  /* Chrome 10-25, Safari 5.1-6 */
  bbackground: linear-gradient(to top, #A6FFCB, #12D8FA, #1FA2FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

a, a:hover {
  text-decoration: none !important;
  color: #212121;
}

* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
}

.hidden {
  display:none !important;
}

.navbar-brand {
  font-weight:800;
  font-size: 24px;
  color: #fff !important;
}


.navbar {
  border-bottom: 1px solid rgba(255,255,255,0.03);
  background-color: rgba(43,42,177,0.96);
}

.icon-container {
  background-color: #f5f5f5;
  border-radius: 20px;
  height: 32px;
  padding: 8px 16px;
  line-height: 16px;
  font-size: 12px;
  color: #212121;
}

.playIcon {
  cursor: pointer;
  height: 16px;
}



.card-footer {
  background-color: rgba(0,0,0,.02);
  border-top: 1px solid #f1f1f1;
}



/* Small devices (portrait phones, 576px and below) */
@media (max-width: 576px) { 
  .card-columns {
    column-count: 1;
  }

}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
  .card-columns {
    column-count: 2;
  }

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .card-columns {
    column-count: 3;
  }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .card-columns {
      column-count: 3;
    }
 
 }


 @media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-property: left, visibility;
  }
  .offcanvas-collapse.open {
    left: 0;
    visibility: visible;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 { color: rgba(255, 255, 255, .5); }

.bg-purple { background-color: #6f42c1; }

.lh-100 { line-height: 1; }
.lh-125 { line-height: 1.25; }
.lh-150 { line-height: 1.5; }

.card {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
  transition-delay: .1s;
  border-radius: 20px;
  transform: translateZ(0);
  background-color: #ffffff;
  overflow: hidden;
  hheight: 100%; 
  border: 0px solid #f1f1f1; 
  color: white;
  cursor: pointer;
  margin-bottom: 16px !important;
}

.card:hover {
  opacity: 1 !important;
  box-shadow: rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px, rgba(35,35,35,0.05) 0px 64px 64px;
  transform: translate(0, -4px);
  z-index: 999;
}

.card:focus, .card:active {
  transform: scale(0.95);
}

.createText, .createCollection {
  padding: 16px;
  margin-bottom: 80px;
  hheight: 102px;
  border-radius: 32px 32px 0 0;
  background-color: rgba(255,255,255,0.9);
  box-shadow: rgba(45,45,45,0.02) 0px -2px 2px, rgba(49,49,49,0.02) 0px -4px 4px, rgba(42,42,42,0.02) 0px -8px 8px, rgba(42,42,42,0.02) 0px -16px 16px;
  bbox-shadow: rgba(45,45,45,0.05) 0px -2px 2px, rgba(49,49,49,0.05) 0px -4px 4px, rgba(42,42,42,0.05) 0px -8px 8px, rgba(32,32,32,0.05) 0px -16px 16px, rgba(49,49,49,0.05) 0px -32px 32px, rgba(35,35,35,0.05) 0px -64px 64px;
  z-index: 999;
}

.createText .form-control, .createCollection .form-control{
  border-radius: 16px;
  border: 0;
  font-size: 14px;
  height: 54px;
}

.createText #createButton, .createCollection #createButton {
  border:1px solid white;
  width: 54px;
  padding: 14px;
  border-radius: 16px;
  background: none;
}

.form-control {
  font-size: 14px;
}

.createText #sendButton, .createCollection #sendButton{
  opacity: 0.3;
}

.createText .input-group, .createCollection .input-group {
  border: 0;
}

.createText .input-group:focus-within, .createCollection .input-group:focus-within {
  border: 0;
}

.createText .input-group-append, .createCollection .input-group-append {
  bbackground-color: #ffffff;
  border-radius: 16px;
}

.p-30 {
  padding: 30px;
}

.t-white-12 {
  color: white;
  font-size: 12px;
}

.t-800 {
  font-weight: 800 !important;
}

.input-group {
  hheight: 44px;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
}

.input-group:focus-within {
  bbackground: rgba(0,0,0,0.4);
  border: 1px solid rgba(0,0,0,0.5);
}

.input-group-text {
  background: none;
  border: 0;
  color: #212121;
  font-size: 20px;
  padding: 8px;
}

.icon-bg {
  background: #e0e0e0;
  border-radius: 38px;
  height: 38px;
  width: 38px;
  line-height: 38px;
}

.form-control:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,0.5);
}

#signIn ::placeholder{
  color: ##9e9e9e;
}

#signIn .form-control {
  background: none;
  border: 0;
  font-size: 14px;
  height: auto;
  color: #212121;
}

.btn-white {
  background-color: white;
  color: black;
  font-size: 14px;
  height: 50px;
}

.btn-transparent {
  background-color: none;
  color: black;
  font-size: 14px;
  height: 50px;
}

.btn-primary {
  background-color: #000000;
  color: #ffffff;
  font-size: 14px;
  height: 50px;
  border: 0;
  border-radius: 16px;
}

.signup-block {
  margin-top: 209px;  
}

/* New css */
.light {
  color: #212121;
}

.dark {
  color: #ffffff;
}


.page-title {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.text-h1 {
  font-size: 48px;
  font-weight: 200;
  line-height: 60px;
}

.text-h2 {
  font-size: 32px;
  font-weight: 200;
}

.text-disabled {
  color: #bdbdbd;
}
.text-small {
  font-size: 12px;
  font-weight: 400;
}

.text-h2-bold {
  font-size: 32px;
  font-weight: 800;
}

.text-icon {
  font-size: 16px;
  color: #212121;
}

.page-header {
  bborder-bottom: 1px solid rgba(102, 71, 71, 0.03);
  background-color:#fafafa;
  hheight: 100px;
  padding: 16px 16px;
  border-radius: 0;
}

.navbar-title {
  font-weight: 300;
  font-size: 16px;
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-title b {
  font-weight: 800;
}

.card-text {
  text-transform: lowercase;
  color: #212121;
  font-size: 24px;
  font-weight: 800;
}

.your-stories .card-title {
  font-size: 24px;
  font-weight: 200;
}

.card-text-container {
  width: 100%;
  padding: 0 0 32px 0;
}

.card-body {
  padding: 16px;
}

.actions .icon-container:hover {
  background-color: #000000;
  color: #ffffff;
}

.actions .disabled:hover {
  background-color: #f5f5f5;
  color: #bdbdbd;
}

.p-32 {
  padding: 32px !important;
}

.home .page-title {
  margin-top: 0;
  line-height: 40px;
  font-size: 40px;
  margin-bottom: 72px;
}

.home .page-title b {
  font-weight: 800;
}

.home .card, .libraries .card {
  width: 311px;
  height: 389px;
  margin-bottom: 32px;
  padding: 32px;
  cursor: pointer;
}

.home .card.small {
  width: 150px;
  height: 116px;
}

.home .card.small .card-title {
  font-size: 18px;
  font-weight: 200;
}


.card-footer {
  padding-left: 0;
  background: none;
  border-top: 0 solid rgba(0,0,0,0.08);
}

.home #en, .libraries #en {
  background-color: #00A7F6;
}

.badge-en {
  background-color: #00A7F6;
  color: #ffffff;
}

.badge-es {
  background-color: #FFC200;
  color: #212121;
}


.home #es, .libraries #es {
  background-color: #FFC200;
  color: #212121;
}

.library {
  padding: 32px;
}

.collection {
  padding: 32px 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.m-32 {
  margin: 32px;
}

.m-16 {
  margin: 16px;
}

.featured {
  overflow: auto;
  margin-left: -32px;
  margin-right: -32px;
}


.featured .list {
  overflow-x: scroll;
  width: 1400px;
  padding-left: 32px;
}

.featured .product-card-image {
  height: 200px;
  width: auto;
  margin: 32px 32px 48px 0;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.05);
  box-shadow: rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px;
  transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
  transition-delay: .1s;
  border-radius: 8px;
  transform: translateZ(0);
  background-color: rgba(255,255,255,1);
  overflow: hidden;
  hheight: 100%; 
  border: 0px solid #f1f1f1; 
  mmargin: 20px 10px;
  color: white;
  cursor: pointer;
}

.featured .product-card-image:hover {
  box-shadow: rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px;
  opacity: 1 !important;
  bbox-shadow: rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px, rgba(35,35,35,0.05) 0px 64px 64px;
  transform: translate(0, -4px);
  z-index: 999;
}

.featured .product-card-image:focus, .featured .product-card-image:active {
  transform: scale(0.95);
}

.featured .product-card-image.small {
  height: 122px;
}

.featured .product-card-image.large {
  height: 278px;
}
.featured.showcase {
  overflow: hidden;
  margin: 0;
}
.featured.showcase .product-card-image {
  margin: 0 0 32px 0;

}

.featured .card {
  height: 265px;
  width: 225px;
  margin: 32px 32px 32px 0;
  padding: 32px;
}

.featured .card:hover {
  box-shadow: rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px;
}

.text-list {
  margin-bottom: 140px;
}

.collections-list {
  margin-bottom: 80px;
}

.home, .libraries {
  margin-bottom: 80px;
}

.collections-list .actions{
  color: #212121;
  font-size: 12px;
  margin-left: 32px;
}

.collections-list .card-text {
  text-transform: none;
  font-size: 16px;
  font-weight: 400;
}

.word {
  padding: 32px;
}

.word .text-h1 {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 64px;
  text-transform: lowercase;
}

.word .card {
  color: #212121;
  margin-bottom: 32px;
}

.word .card-header {
  background: none;
  border: 0;
  font-size: 14px;
  font-weight: 800;
}

.word .card-body {
  padding: 16px 32px 32px 32px;
}

.word .no-results,
.word .error {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
}

.lexical-category, 
.wiki-entry-title {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  text-transform: lowercase;
  margin-bottom: 8px;
  color: #212121;
}

.wiki-entry-container {
  margin-bottom:32px;
}

.bookmark-container {
  position: absolute;
  margin-left: -33px;
  font-size: 16px;
}

.wiki-entry-title {
  text-transform: none;
}

.lexical-entry-container p, .wiki-entry-container p{
  margin-left: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #9E9E9E;
}

.wiki-entry-container p{
  margin-left: 0px;
}

.lexical-entry-container p b{
  font-size: 12px;
  font-weight: 800;
  color: #212121;
}

.rotate {
  transform: rotate(-90deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.bg-deep-purple {
  background-color: #6733B9
}

.bg-deep-orange {
  background-color: #FF5609
}

.bg-light-green {
  background-color: #89C341;
}

.bg-cyan {
  background-color: #00BBD5;
}

.bg-indigo {
  background-color: #3D4DB7;
}

.bg-teal {
  background-color: #009587;
}

.bg-amber {
  background-color: #FFC200;
}

.bg-orange {
  background-color: #FF9800;
}

.bg-purple {
  background-color: #9D1AB2;
}

.bg-red {
  background-color: #F6412D;
}

.modal-dialog {
  position:fixed;
  top:25%;
  right:auto;
  bottom:0;
  margin: 0;
  width: 100.3%;
  left: 50%;
  transform: translate(-50%, 0);
}  

.modal-content {
  margin: 0;
  border-radius: 32px 32px 0 0;
  border: 0;
  box-shadow: rgba(45,45,45,0.1) 0px -2px 2px, rgba(49,49,49,0.1) 0px -4px 4px, rgba(42,42,42,0.1) 0px -8px 8px, rgba(32,32,32,0.1) 0px -16px 16px, rgba(49,49,49,0.1) 0px -32px 32px, rgba(35,35,35,0.1) 0px -64px 64px;
  bottom: 0;
  background-color: #fafafa;
}

.modal-header, .modal-body, .modal-footer {
  padding: 32px;
  border: 0;
}

.modal-body {
  padding-top: 0;
}

.showcase .description {
  font-size: 12px;
  font-weight: 400;
  color: #9E9E9E;
}

.show-menu .page-header {
  transition: transform 1s;
  transform: translate(220px,0px);
  border-radius: 32px 0 0 0;
}

.hide-menu .page-header {
  transition: all 1s;
  transform: translateX(0%);
  border-radius: 0;
}

.show-menu .home {
  transition: transform 1s;
  transform: translate(220px,0px);
  background-color: #fafafa;
}

.hide-menu .home {
  transition: transform 1s;
  transform: translateX(0%);
  background-color: #fafafa;
}

.show-menu, .hide-menu {
  background-color: #212121;
}

.home {
  background-color: #fafafa;
}

.side-menu {
  color: rgba(255,255,255, 1);
  font-size: 16px;
  font-weight: 200;
  position: fixed;
  top:0;
  left: 0;
  padding: 64px 32px 32px 32px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100%);
}

.side-menu a {
  color: rgba(255,255,255,1) !important;
}

.sub-menu {
  margin-top: 80px;
  margin-bottom: 40px;
}

.side-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 54px;
}

.sub-menu li {
  font-size: 12px;
}

.side-menu li {
  margin-bottom: 24px;
}

.side-menu li i {
  margin-right: 16px;
}

.tab-bar {
  padding: 10px;
  height: 80px;
  background-color: #fafafa;
  border-top: 0 solid #e0e0e0;
  box-shadow: rgba(45,45,45,0.05) 0px -1px 8px;
  bbox-shadow: rgba(45,45,45,0.05) 0px -2px 2px, 
              rgba(49,49,49,0.05) 0px -4px 4px, 
              rgba(42,42,42,0.05) 0px -8px 8px, 
              rgba(32,32,32,0.05) 0px -16px 16px, 
              rgba(49,49,49,0.05) 0px -32px 32px, 
              rgba(35,35,35,0.05) 0px -64px 64px;
}

.tab-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 29px;
}

.tab-bar li {
  text-align: center;
  padding: 4px 16px;
  line-height: 32px;
  ffont-size: 0.9rem;
}


.tab-bar li.selected {
  bbackground: #e0e0e0;
  bborder-radius: 64px;
  mmargin: 0 32px;
}

.tab-bar li:first-child.selected {
  mmargin-left: 0;
}

.tab-bar li:last-child.selected {
  mmargin-right: 0;
}

.tab-bar li a{
  color: #9e9e9e !important;
}

.tab-bar li.selected a{
  color: #212121 !important;
}

.tab-bar li a span {
  display: block;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 1px;
}

.tab-bar li.selected a span {
  font-weight: 900;
}

.tab-bar i {
  font-style: normal !important;
}

#user-image {
  margin-bottom: 40px;
  border-radius: 34px;
  background-color: yellow;
}

.app-footer div {
  line-height: 0;
  font-size: 12px;
  margin-right: 8px;
  color: rgba(0,0,0,0.72);
}

.app-footer p {
  font-weight: 800;
}

.app-footer p i {
  font-weight: 600;
}

.app-footer span {
  font-size: 10px;
  font-weight: 500;
}

.show-menu .side-menu {
  transition: all 1s;
  opacity: 1;
}

.hide-menu .side-menu {
  transition: all 1s;
  opacity: 0;
}

.show-menu {
  background-color: #212121;
}

.hide-menu {
  transition: all 2s;
  background-color: #fafafa;
}

.hide-menu #arrow-btn {
  display: none;
}

.hide-menu #menu-btn {
  transition: all 1s;
  display:block;
}

.show-menu #arrow-btn {
  transition: all 1s;
  display:block;
}

.show-menu #menu-btn {
  transition: all 1s;
  display: none;
}

.stories {
  padding: 32px;
}

.stories .card {
  width: 311px;
  margin-bottom: 32px;
  cursor: pointer;
}

.store {
  padding: 32px;
}

.more {
  padding: 32px;
}

.profile {
  padding: 32px;
}

.actionButton {
  margin-right: 16px;
}

/* New Tab Design */
.tab-bar-parent {
  padding: 0 16px;
}

.tab-bar {
  margin: auto;
  margin-bottom: 21px !important;
  padding: 8px;
  height: 60px;
  border-radius: 30px;;
  bbackground-color: #121525;
  background-color: rgba(0,0,0,0.8);
  backdrop-filter: blur(5px);
  box-shadow: rgba(45,45,45,0.5) 0px 3px 5px;
  transition: all 0.2s;
  width: 240px;
}

.tab-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 29px;
}

.tab-bar li {
  text-align: center;
  padding: 0;
  line-height: 48px;
  width: 44px;
  height: 44px;
  font-size: 20px;
  background-color: rgba(255,255,255,0);
  border-radius: 22px;
}

.tab-bar li a{
  color: #90949f !important;
}

.tab-bar li.selected a{
  color: #fff !important;
  ccolor: #f79d6f !important;
  bbackground: -webkit-linear-gradient(135deg, #9d6dfd 10% 30%, #1fff97 50% 70%, #febc42 70% 100%);  /* Chrome 10-25, Safari 5.1-6 */
  bbackground: linear-gradient(135deg, #9d6dfd 10% 30%, #1fff97 50% 70%, #febc42 70% 100%); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  -wwebkit-background-clip: text;
  -wwebkit-text-fill-color: transparent;
  font-size: 26px;
  transition: all 12s;
}

.tab-bar li a span {
  display: none;
  font-size: 9px;
  line-height: 17px;
  letter-spacing: 1px;
}

.tab-bar li.selected a span {
  font-weight: 900;
}

.tab-bar i {
  font-style: normal !important;
}

.create-text-parent {
  padding: 0 16px;
}

.createText {
  transition: all 1s;
}

.createText, .createCollection {
  max-width: 358px;
  padding: 8px;
  margin-bottom: 92px;
  hheight: 60px;
  border-radius: 30px;
  border: 1px solid rgba(45,45,45,0.1);
  background-color: rgba(255,255,255,0.9);
  bbox-shadow: rgba(45,45,45,0.02) 0px -2px 2px, rgba(49,49,49,0.02) 0px -4px 4px, rgba(42,42,42,0.02) 0px -8px 8px, rgba(42,42,42,0.02) 0px -16px 16px;
  box-shadow: rgba(45,45,45,0.05) 0px 0px 8px;
  z-index: 999;
  backdrop-filter: blur(5px);
}

.createText .form-control, .createCollection .form-control{
  bborder-radius: 16px;
  border: 0;
  font-size: 14px;
  height: 44px;
}

.createText #createButton, .createCollection #createButton {
  border:1px solid rgba(255,255,255,0);
  width: 44px;
  padding: 0;
  height: 44px;
  border-radius: 22px;
  background: none;
}

.form-control {
  font-size: 14px;
}

.createText #sendButton, .createCollection #sendButton{
  opacity: 0.3;
}

.createText .input-group, .createCollection .input-group {
  border: 0;
}

.createText .input-group:focus-within, .createCollection .input-group:focus-within {
  border: 0;
}

.createText .input-group-append, .createCollection .input-group-append {
  bbackground-color: #ffffff;
  border-radius: 16px;
}

.createText .form-control {
  background: none;
}

.createText .p-4 {
  padding: 1rem !important;
}

.page-header {
  background-color: rgba(250,250,250,1);
  backdrop-filter: blur(5px);
  background: linear-gradient(0deg, rgba(250,250,250,0) 0%, rgba(255,250,250,0) 10%, rgba(250,250,250,1) 100%);
  bbackground: linear-gradient(0deg, rgba(250,250,250,0) 0%, rgba(255,250,250,0) 10%,rgba(250,250,250,1) 90%, rgba(250,250,250,1) 100%);
  bbackground: radial-gradient(ellipse at 50% 150%, rgba(255,0,0,0) 0%, rgba(255,0,0,0.8) 100%);
}
